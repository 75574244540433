import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

import type { PragmaticJackpot } from './types'

const getPragmaticJackpot = (state: ReduxState) => state.pragmaticJackpot

export const getPragmaticJackpotsSelector = createSelector(
  getPragmaticJackpot,
  (pragmaticJackpot) => pragmaticJackpot.jackpots
)

export const getMaxPragmaticJackpotsSelector = createSelector(getPragmaticJackpotsSelector, (jackpots) => {
  return jackpots.reduce(
    (acc, jackpot) => (Number(jackpot.amount) > Number(acc?.amount || 0) ? jackpot : acc),
    {} as PragmaticJackpot
  )
})

export const getMaxPragmaticJackpotsByGameCodeSelector = createSelector(
  getMaxPragmaticJackpotsSelector,
  (_: ReduxState, code: string) => code,
  (jackpot, code) => {
    return jackpot.games?.includes(code) ? jackpot.amount : null
  }
)
