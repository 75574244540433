import type { Provider } from '@patrianna/shared-patrianna-types/store/ProviderModule'
import { useState } from 'react'

import GameRowHeader from 'components/GameRowHeader'
import GameProvidersList from 'containers/GameProviders/components/GameProvidersList'
import HorizontalScroll from 'containers/HorizontalScroll'
import ROUTES from 'temp/routes.json'

type Props = {
  providers: Provider[]
  title?: string
  className?: string
  isMobileUserAgent: boolean
  hideHeader?: boolean
}

export default function GameProviders({ providers, title, className, isMobileUserAgent, hideHeader }: Props) {
  const [scrollPage, setScrollPage] = useState(0)
  const scrollNext = () => {
    setScrollPage((prevState) => prevState + 1)
  }

  const scrollPrev = () => {
    setScrollPage((prevState) => prevState - 1)
  }

  return (
    <>
      {!hideHeader && (
        <GameRowHeader
          title={title}
          url={ROUTES.PROVIDERS}
          showArrows={!isMobileUserAgent}
          className={className}
          scrollPrev={scrollPrev}
          scrollNext={scrollNext}
        />
      )}
      <HorizontalScroll BackDropNode={null} scrolledItemDataAttr='data-banner' scrollPage={scrollPage}>
        <GameProvidersList providers={providers} />
      </HorizontalScroll>
    </>
  )
}
