import type { Provider } from '@patrianna/shared-patrianna-types/store/ProviderModule'
import cx from 'classnames'

import { Link } from 'components/Link'
import ROUTES from 'temp/routes.json'

import classes from './styles.module.scss'

type GameProvidersListProps = {
  providers: Provider[]
  containerClassName?: string
}

export default function GameProvidersList({ providers, containerClassName }: GameProvidersListProps) {
  return (
    <ul className={cx(classes.root, containerClassName)}>
      {providers.map((provider) => {
        return (
          <li className={classes.listItem} key={provider.code}>
            <Link href={`${ROUTES.PROVIDERS}/${provider.code}`} className={classes.imgWrapper}>
              <img
                src={`${process.env.IMG_ORIGIN}/game_providers/${provider.code}_v2.svg?v3`}
                alt={provider.title}
                className={classes.img}
                loading='lazy'
              />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
