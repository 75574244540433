import { getSafeAnchorHref, hasLinksInField, makeLinksRelative } from '@patrianna/shared-utils/prismic'
import type { JSXFunctionSerializer, JSXMapSerializer } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import type { MouseEvent } from 'react'

import { useRouter } from 'app/context/navigation'

type RichTextProps = {
  field: string
  components?: JSXMapSerializer | JSXFunctionSerializer
}

function RichText(props: RichTextProps) {
  const router = useRouter()
  const { field, components } = props
  const isTranslationString = typeof field === 'string'
  const isLinksInField = !isTranslationString && hasLinksInField(field)
  const fieldWithCorrectLinksPath = !isTranslationString && makeLinksRelative(field)

  const renderFields = (fieldWithCorrectLinksPath || field) as never

  const handleClick = (e: MouseEvent) => {
    const href = getSafeAnchorHref(e)

    if (href) {
      e.preventDefault()
      router.push(href)
    }
  }

  if (isTranslationString) {
    return <>{renderFields}</>
  }

  return isLinksInField ? (
    <div onClick={handleClick}>
      <PrismicRichText field={renderFields} components={components} />
    </div>
  ) : (
    <PrismicRichText field={renderFields} components={components} />
  )
}

export default RichText
