'use client'

import type { GameProductShort, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'

import { Link } from 'components/Link'
import { useAppDispatch } from 'store/hooks'
import { closeAllDialogs } from 'store/modules/dialog/actions'
import images from 'temp/images.json'
import { getSlotStartUrlByGameTags } from 'utils/navigator/games'

import { Picture } from '../Picture'
import { ImageType, type PictureProps } from '../Picture/Picture.types'

import TilesBottomBar from './components/ProductTilesBottomBar'
import TilesTopBar from './components/ProductTilesTopBar'
import classes from './styles.module.scss'

export type Props = {
  game: GameProductShort
  className?: string
  moreRows?: boolean
  handleTileClick?: (gameCode: string) => void
  isSearch?: boolean
  swimlaneStyle?: SwimlaneCategoryStyle
}

function ProductTile({ game, className, handleTileClick, isSearch, swimlaneStyle }: Props) {
  const dispatch = useAppDispatch()
  const url = `${getSlotStartUrlByGameTags(game.tags)}/${game.route}`

  const pictureProps: PictureProps = {
    src: `${images.bucket}/tiles/${game.code}${game.animatedTile ? '/animation' : ''}/source.png`,
    alt: game.title,
    className: classes.image,
    type: isSearch ? ImageType.SEARCH : ImageType.TILE,
    loading: 'lazy',
  }

  return (
    <Link
      href={url}
      className={cx(
        classes.root,
        classes.rootVisible,
        className && {
          [className]: className,
        }
      )}
      onClick={() => {
        handleTileClick?.(game.route)
        dispatch(closeAllDialogs())
      }}
    >
      <div className={classes.imageContainer} data-test={`game-tile-${game.code}`}>
        <TilesTopBar game={game} swimlaneStyle={swimlaneStyle} />
        <Picture {...pictureProps} />
        {game.animatedTile && (
          <img
            src={`${images.bucket}/tiles/${game.code}/animation/animated_foreground.svg`}
            alt='animated-foreground'
            className={classes.animatedLogo}
          />
        )}
        <TilesBottomBar game={game} />
      </div>
    </Link>
  )
}

export default ProductTile
