import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

import type { HabaneroJackpot } from './types'

const getHabaneroJackpot = (state: ReduxState) => state.habaneroJackpot

export const getHabaneroJackpotsSelector = createSelector(
  getHabaneroJackpot,
  (habaneroJackpot) => habaneroJackpot.jackpots
)

export const getRaceHabaneroJackpotsSelector = createSelector(getHabaneroJackpotsSelector, (jackpots) => {
  const raceJackpot = jackpots.find((el) => el.level === 'Race')

  return raceJackpot || ({} as HabaneroJackpot)
})

export const getRaceHabaneroJackpotsByGameCodeSelector = createSelector(
  getRaceHabaneroJackpotsSelector,
  (_: ReduxState, code: string) => code,
  (jackpot, code) => {
    return jackpot.games?.includes(code) ? jackpot.amount : null
  }
)
