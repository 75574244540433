import type { TagsType, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'

import classes from './ProductTileTags.module.scss'
import { getTagClassName, getTagImage, isDefaultGameProductTags } from './utils'

type Props = {
  tags: TagsType[]
  tagSize?: SwimlaneCategoryStyle
}

export default function ProductTileTags({ tags, tagSize }: Props) {
  const isDefaultTags = isDefaultGameProductTags(tags)
  const tagImage = getTagImage(tags, tagSize)

  return (
    <div className={classes.tags}>
      {tagImage ? (
        <img className={tagImage.className} src={tagImage.src} alt={tagImage.alt} />
      ) : (
        <div
          className={cx(classes.textTags, {
            [classes.textTagsLarge]: tagSize === 'large',
            [classes.textTagsSmall]: tagSize === 'small',
            [classes.textTagsDefault]: tagSize === 'default',
          })}
        >
          {isDefaultTags.map((tag, idx) => (
            <span key={tag} className={getTagClassName(tag, idx)}>
              {tag}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}
