import type { RefObject } from 'react'
import { useEffect, useState } from 'react'

const DEFAULT_COUNT_OF_LINES = 2 // Two lines of text

export const useCheckExpandableContent = (ref: RefObject<HTMLElement>, content: any, countOfLines?: number) => {
  const [isExpandable, setIsExpandable] = useState<boolean>(false)

  useEffect(() => {
    if (ref.current) {
      const lineHeight = parseInt(window.getComputedStyle(ref.current).lineHeight, 10)
      const maxHeight = lineHeight * (countOfLines || DEFAULT_COUNT_OF_LINES)
      ref.current.scrollHeight > maxHeight ? setIsExpandable(true) : setIsExpandable(false)
    }
  }, [ref, content, countOfLines])

  return isExpandable
}
