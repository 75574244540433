import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'
import { useRef, useState } from 'react'

import RichText from 'components/RichText'
import { useCheckExpandableContent } from 'hooks/useCheckExpendableContent'
import { useManageTabIndex } from 'hooks/useManageTabIndex'

import classes from './styles.module.scss'

// @TODO change type of content
export type Props = {
  content: any
  isReadMore?: boolean
  isFooterContent?: boolean
}

export default function CategoryDescription(props: Props) {
  const { content, isReadMore, isFooterContent } = props
  const [showFullText, setShowFullText] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const t = useTranslation()

  const isExpandable = useCheckExpandableContent(contentRef, content)
  useManageTabIndex(contentRef, showFullText)

  const toggleShowFullText = () => {
    setShowFullText(!showFullText)
  }

  if (!content || content.length === 0 || !content[0].text) {
    return null
  }

  return (
    <div className={cx(classes.root, { [classes.show]: showFullText }, { [classes.footer]: isFooterContent })}>
      <div
        ref={contentRef}
        className={cx(
          classes.container,
          { [classes.expanded]: !isReadMore },
          { [classes.readMore]: isReadMore && isExpandable }
        )}
      >
        <RichText field={content} />
      </div>

      {isReadMore && isExpandable && (
        <button type='button' className={classes.button} onClick={toggleShowFullText}>
          {showFullText ? t('common.less') : t('common.more')}
        </button>
      )}
    </div>
  )
}
