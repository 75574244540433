'use client'

import { useTranslation } from '@patrianna/core-components'
import type { GameProduct, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import { intlnumberFormat } from '@patrianna/shared-utils/numbers'
import cx from 'classnames'

import { useAuth } from 'app/context/auth'
import { useAppSelector } from 'store/hooks'
import { getMaxPragmaticJackpotsByGameCodeSelector } from 'store/modules/pragmaticJackpots/selectors'
import { isPragmaticJackpotGameProduct } from 'utils/games'

import classes from './styles.module.scss'

type PragmaticJackpotProps = {
  className?: string
  gameProduct: GameProduct | GameProductShort
}

function PragmaticJackpot(props: PragmaticJackpotProps) {
  const { gameProduct } = props
  const t = useTranslation()
  const { isScEnabled } = useAuth()
  const isPragmaticJackpotPlayGame = isPragmaticJackpotGameProduct(gameProduct)
  const maxPragmaticJackpot = useAppSelector((state) =>
    getMaxPragmaticJackpotsByGameCodeSelector(state, gameProduct.code)
  )

  if (!isPragmaticJackpotPlayGame || !maxPragmaticJackpot) {
    return null
  }

  return (
    <div className={cx('mt-typography-caption', classes.root, isScEnabled ? classes.sc : classes.gc, props.className)}>
      {isScEnabled ? t('common.sweepstakes_coins_sc') : t('common.gold_coin_gc')}{' '}
      {intlnumberFormat(maxPragmaticJackpot, 'en-US', {
        style: 'decimal',
        minimumFractionDigits: isScEnabled ? 2 : 0,
        maximumFractionDigits: isScEnabled ? 2 : 0,
      })}
    </div>
  )
}

export default PragmaticJackpot
