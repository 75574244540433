export enum ImageType {
  BANNER = 'banner',
  TILE = 'tile',
  SEARCH = 'search',
  SCREENSHOT = 'screenshot',
  GAME_DESCRIPTION = 'gameDescription',
  PROMO_HIGHLIGHTS = 'promo-highlights',
}

export interface PictureProps {
  src: string
  alt: string
  className?: string
  loading?: 'lazy' | 'eager'
  type: ImageType
  priority?: boolean
}
