import cx from 'classnames'

import classes from './styles.module.scss'

type Props = {
  length?: number
  className?: string
}

export default function Skeleton({ length = 1, className }: Props) {
  return Array.from({ length }).map((_, index) => <div key={index} className={cx(classes.skeleton, className)} />)
}
