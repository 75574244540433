import type { RefObject } from 'react'
import { useEffect } from 'react'

export const useManageTabIndex = (ref: RefObject<HTMLElement>, isVisible: boolean) => {
  useEffect(() => {
    if (ref.current) {
      const focusableElements = ref.current.querySelectorAll('a, [tabindex]')
      focusableElements.forEach((element) => {
        !isVisible ? element.setAttribute('tabindex', '-1') : element.removeAttribute('tabindex')
      })
    }
  }, [ref, isVisible])
}
